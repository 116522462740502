import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import React from "react";

import PowerOff from "assets/icons/Power-Off-Red.svg";

import "./LogOutButton.scss";

interface Props {
  className?: string;
}

const handleLogout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
};

export const LogOutButton: React.FC<Props> = ({ className }) => {
  const { instance } = useMsal();

  return (
    <button
      className={`btn btn--sign-out ${className}`}
      onClick={() => handleLogout(instance)}>
      <span className="btn__icon">
        <PowerOff />
      </span>
      <span>Log out</span>
    </button>
  );
};
