import { SEO } from "components/SEO";
import ProfilePage from "modules/profile/ProfilePage";
import React from "react";

const Profile: React.FC = () => {
  return (
    <>
      <SEO title="Profile" />
      <ProfilePage />
    </>
  );
};

export default Profile;
