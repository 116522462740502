import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const getMyUserProfileDetails = async () => {
  const accessToken = await acquireAccessToken(msalInstance);
  const response = await fetch(
    `${process.env.BASE_API_URL}/UserProfile/GetMyUserProfileDetails`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const user: User = await response.json();

  console.log(user);

  return {
    user: user,
  };
};
