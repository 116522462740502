import { RouteComponentProps } from "@reach/router";
import { userSelector } from "modules/user";
import { useGetMyUserProfileDetails } from "modules/user/hooks/useGetMyUserProfileDetails";
import { saveMyUserProfileDetails } from "modules/user/http/saveMyUserProfileDetails";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const PersonalDetails: React.FC<RouteComponentProps> = () => {
  // TODO: this should be in the useEffect as it should not run everytime user visits the page
  useGetMyUserProfileDetails();
  const user = useSelector(userSelector.getMyUserProfile);
  const { register, handleSubmit, reset } = useForm<User>();

  let existingUser: User;
  useEffect(() => {
    if (user !== undefined) {
      existingUser = user;
    }
    reset(user)
  }, [reset, user]);

  const onSubmit = (data: GetUserProfileDetails) => {
    saveMyUserProfileDetails(data);
  };

  return (
    <>
      <header className="profile-content__header profile-content__header--with-heading">
        <h2 className="profile-content__heading">A little bit more about me</h2>
        <p className="profile-content__description">
          These informations are required when applying for an Opportunity. In
          this way, we can learn more about you before we meet each other in
          person. If you leave these fields empty, we’ll require them every time
          you apply for an Opportunity.
        </p>
      </header>

      <section className="profile-content__body">
        <form onSubmit={handleSubmit(onSubmit)} className="login__form">
          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="phoneNumber">
              Phone Number
            </label>
            <input
              className="input__field"
              type="text"
              id="phoneNumber"
              {...register("phoneNumber")}
            />
          </div>
          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="address">
              Address
            </label>
            <input
              className="input__field"
              type="text"
              id="address"
              {...register("address")}
            />
          </div>
          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="tShirtSize">
              T-shirt Size
            </label>
            <input
              className="input__field"
              type="text"
              id="tShirtSize"
              {...register("tShirtSize")}
            />
          </div>

          <div className="login__buttons" role="presentation">
            <button type="submit" className="btn btn--primary btn--block">
              Save Changes
            </button>
            <button type="button" className="btn btn--ghost btn--block" onClick={() => { reset(existingUser) }}>
              Cancel
            </button>
          </div>
        </form>
      </section>
    </>
  );
};

export default PersonalDetails;
