import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";
import { getMyUserProfileDetails } from "./getMyUserProfileDetails";

const msalInstance = new PublicClientApplication(msalConfig);

export const saveMyUserProfileDetails = async (
  payload: GetUserProfileDetails
) => {
  const accessToken = await acquireAccessToken(msalInstance);

  await fetch(
    `${process.env.BASE_API_URL}/UserProfile/SaveMyUserProfileDetails`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }
  );

  console.log(payload);

  getMyUserProfileDetails();
};
