import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LogOutButton } from "components/Buttons/LogOutButton/LogOutButton";
import { useSelector } from "react-redux";
import { useGetMyUserProfile, userSelector } from "modules/user";
import { RouteComponentProps } from "@reach/router";
import { saveMyUserProfile } from "modules/user/http/editUser";

import { getCorrectDate } from "modules/app/utils/getCorrectDate";

const AccountInformations: React.FC<RouteComponentProps> = () => {
  // TODO: this should be in the useEffect as it should not run everytime user visits the page
  useGetMyUserProfile();
  const user = useSelector(userSelector.getMyUserProfile);
  const [existingUser, setExistingUser] = useState<User>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<User>();

  useEffect(() => {
    if (user !== undefined) {
      setExistingUser(user);
    }
    reset({
      ...user,
      dateOfBirth: getCorrectDate(user?.dateOfBirth),
    });
  }, [reset, user]);

  const onReset = () => {
    reset({
      ...existingUser,
      dateOfBirth: getCorrectDate(existingUser?.dateOfBirth),
    });
  };

  const onSubmit: SubmitHandler<User> = (data: User) => {
    saveMyUserProfile(data).then(() => {
      reset(data);
      setExistingUser(data);
    });
  };

  return (
    <>
      <section className="profile-content__body">
        <form onSubmit={handleSubmit(onSubmit)} className="login__form">
          <input
            type="text"
            id="photoFileName"
            {...register("photoFileName")}
            className="visually-hidden"
            aria-hidden="true"
          />

          <p className="user__id">User ID: {user?.assignedId}</p>

          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="firstName">
              Name
            </label>
            <input
              readOnly
              className="input__field"
              type="text"
              id="firstName"
              {...register("firstName", { required: true, minLength: 2 })}
            />
            {errors.firstName && <span>This field is required</span>}
          </div>
          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="lastName">
              Last Name
            </label>
            <input
              readOnly
              className="input__field"
              type="text"
              id="lastName"
              {...register("lastName", { required: true, minLength: 2 })}
            />
          </div>
          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="email">
              Email Address
            </label>
            <input
              readOnly
              className="input__field"
              type="email"
              id="email"
              {...register("email")}
            />
          </div>
          <div className="input__group" role="presentation">
            <label className="input__label" htmlFor="dateOfBirth">
              Date of Birth
            </label>
            <input
              className="input__field"
              type="date"
              id="dateOfBirth"
              {...register("dateOfBirth")}
            />
          </div>

          <div className="login__buttons" role="presentation">
            <button type="submit" className="btn btn--primary btn--block">
              Save Changes
            </button>
            <button
              type="button"
              className="btn btn--ghost btn--block"
              onClick={onReset}>
              Cancel
            </button>
          </div>
        </form>
      </section>

      <footer className="profile-content__footer">
        <LogOutButton className="btn--block" />
      </footer>
    </>
  );
};

export default AccountInformations;
