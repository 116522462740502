import { AuthenticatedTemplate } from "@azure/msal-react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import Layout from "components/layout/Layout";
import React from "react";
import PersonalDetails from "./components/PersonalDetails";
// import TermsOfService from "./components/TermsOfService";
import TabbedInterface, {
  TabNavigation,
} from "components/TabbedInterface/TabNavigation";
import AccountInformations from "./components/AccountInformations";

import "./styles/ProfileContent.scss";
// import DataPolicy from "./components/DataPolicy";
// import CookiesPolicy from "./components/CookiesPolicy";

const ProfilePage: React.FC<RouteComponentProps> = () => {
  const navigationTabs: TabNavigation[] = [
    { href: "/profile/account-informations", label: "Account Informations" },
    { href: "/profile/personal-details", label: "Personal Details" },
    // { href: "/profile/terms-of-service", label: "Terms of Service" },
    // { href: "/profile/data-policy", label: "Data Policy" },
    // { href: "/profile/cookies-policy", label: "Cookies Policy" },
  ];

  return (
    <Layout>
      <AuthenticatedTemplate>
        <section className="profile-content">
          <TabbedInterface navigationTabs={navigationTabs} />
          <div
            className="profile-content__container container"
            role="presentation">
            <Router>
              <Redirect
                from="/profile"
                to="/profile/account-informations"
                noThrow
              />
              <AccountInformations path="/profile/account-informations" />
              <PersonalDetails path="/profile/personal-details" />
              {/* <TermsOfService path="/profile/terms-of-service" />
              <DataPolicy path="/profile/data-policy" />
              <CookiesPolicy path="/profile/cookies-policy" /> */}
            </Router>
          </div>
        </section>
      </AuthenticatedTemplate>
    </Layout>
  );
};

export default ProfilePage;
