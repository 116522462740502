import { getMyUserProfile } from "./getUser";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { acquireAccessToken } from "modules/app/utils/acquireAccessToken";

const msalInstance = new PublicClientApplication(msalConfig);

export const saveMyUserProfile = async (payload: User) => {
  const accessToken = await acquireAccessToken(msalInstance);

  await fetch(`${process.env.BASE_API_URL}/UserProfile/SaveMyUserProfile`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  console.log(payload);

  getMyUserProfile();
};
